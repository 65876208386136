/* customScrollbar.css */

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background-color: #868686;
}

::-webkit-scrollbar-thumb {
	background-color: #868686;
}

/* For Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #282828 #050606;
}
