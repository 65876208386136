/* customStyles.css */
.gnw_content_container .headline {
    font-size: 20px;
    color: #333;
  }
  
  .gnw_content_container .description {
    font-size: 200px;
    color: #666;
  }
  /* Targeting direct children of the GNW widget container */
#gnw_widget > div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Example border */
    margin-bottom: 16px; /* Space between news items */
    padding-bottom: 16px; /* Padding at the bottom of each item */
    background-color: rgba(29, 32, 35, 0.6); /* Consistent with your theme */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.1); /* Subtle shadow */
}

/* You might need to adjust the selector based on the actual structure of the GNW widget content */
