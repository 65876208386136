@keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .dot1 { animation: blink 1.4s infinite; }
  .dot2 { animation: blink 1.4s infinite 0.2s; }
  .dot3 { animation: blink 1.4s infinite 0.4s; }
  