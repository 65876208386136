.week-react-aria-Calendar {
  --highlight-background: #05d8a5;
  --highlight-foreground: white;
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --text-color-invalid: var(--spectrum-global-color-red-600);
  --pressed-color: var(--spectrum-global-color-gray-200);
  --unavailable-color: var(--spectrum-global-color-red-600);
  --invalid-color: var(--spectrum-global-color-static-red-600);
  max-width: 100%;
  color: var(--text-color);
}

.react-aria-RangeCalendar {
  & table {
    border-collapse: collapse;

    & td {
      padding: 2px 0;
    }
  }
}
.week-react-aria-CalendarCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  height: 35px;
  width: 35px;
  font-size: 200px;
  outline: none;
  forced-color-adjust: none;
  font-size: medium !important;
  margin-top: 5px;


  &[data-outside-visible-range] {
    color: #868686  }

 

  &[data-selected] {
    color: #05d8a5;
    font-size: large !important;
    background: rgba(29, 32, 35, 0.6);
    font-weight: bolder;
   
    text-align: center;
  }
  &[data-unavailable] {
    color: #282828;
    background: #090A0B;
  }
  
  &[data-selection-start] {
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
  }
 

  &[data-selection-end] {
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
  }
}

.week-react-aria-CalendarHeaderCell {
  color: #05d8a5;
  text-align: center;
  z-index: 2;
  margin-bottom: 20px;
  
}

.week {
  margin-top:-10px;
  margin-bottom:20px;
 
  grid-template-areas:
    "heading heading heading"
    "previous grid next";
  align-items: center;
  justify-items: center;
  
  .react-aria-Heading {
    grid-area: heading;

    font-size: 16px;
  }

  .react-aria-CalendarCell[data-outside-month] {
    text-align: center;
  }
}
