@keyframes snakeBorder {
	0% {
		border-color: transparent;
	}
	25% {
		border-top-color: #05d8a5; /* Your theme color */
	}
	50% {
		border-right-color: #05d8a5; /* Your theme color */
	}
	75% {
		border-bottom-color: #05d8a5; /* Your theme color */
	}
	80% {
		border-left-color: #05d8a5; /* Your theme color */
	}
}

.snake-border-animation {
	border: 2px solid transparent; /* Initial border color */
	animation: snakeBorder 5s linear infinite;
}
