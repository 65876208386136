/* Basic editor styles */
.tiptap > * + * {
	margin-top: 10px;
}

.tiptap {
	margin: 10px;
	line-height: 1;
}
.tiptap ul,
.tiptap ol {
	padding: 0 1rem;
}
.tiptap p {
	color: #868686;
}
.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
	line-height: 1.1;
}

.tiptap h2 {
	font-size: 20px;
}

.is-active {
	background-color: #1c1e22 !important; /* Example color; change as needed */
	color: #fff !important; /* Adjust text color if needed */
}
.tiptap p.is-editor-empty:first-child::before {
	color: #adb5bd;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
}

.tiptap codeBlock {
	background-color: #1c1e22;
}

.tiptap pre {
	background: #1c1e22;
	color: #fff;
	font-family: "JetBrainsMono", monospace;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
}
.ProseMirror:focus {
	outline: none;
}

.ProseMirror {
	height: 200px;
}
.tiptap pre code {
	color: inherit;
	padding: 0;
	background: none;
	font-size: 0.8rem;
}

.tiptap img {
	max-width: 100%;
	height: auto;
}

.tiptap blockquote {
	padding-left: 1rem;
	border-left: 2px solid #1c1e22;
}

.tiptap hr {
	border: none;
	border-top: 2px solid rgba(13, 13, 13, 0.1);
	margin: 2rem 0;
}
