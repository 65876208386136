.react-aria-Calendar-two {
  --highlight-background: #05d8a5;
  --highlight-foreground: white;
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --text-color-invalid: var(--spectrum-global-color-red-600);
  --pressed-color: var(--spectrum-global-color-gray-200);
  --unavailable-color: var(--spectrum-global-color-red-600);
  --invalid-color: var(--spectrum-global-color-static-red-600);

  color: var(--text-color);
}
.react-aria-CalendarGridBody {
  height: 175px; /* 25px height per cell x 7 rows = 175px */
  grid-template-rows: repeat(7, 100px);
  overflow-y: auto;
  
  
}
.date-value-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Take up the full width of the cell */
  padding-left: 8px; /* Space between the rectangle and the date */
  padding-right: 2px;
  
  
  /* Space between the numerical value and the right side of the cell */
}


.react-aria-CalendarCell {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px; /* Adjust height as necessary */
  margin: 20px -2px 0px 0px;
  padding: 10px; /* Provide some spacing */
  width: 150px; 
  box-sizing: border-box; /* Include padding in width and height */
  outline: none;
  position: relative;
  font-weight:bold;
  background:rgba(29, 32, 35, 0.2);
  font-size: medium;
  border-radius: 2px;

  &[data-outside-visible-range] {
    color: #868686  }

  &:hover {
    background: rgba(29, 32, 35, 0.6);
   
  }

  &[data-selected] {
    color: #fff;
    background: rgba(29, 32, 35, 0.6);
    font-weight: bold;
   
    
    
  }
  &[data-unavailable] {
    color: #282828;
  }
}
.rectangle-positive {
  position: absolute;
  left: 0;
  top: 0px; /* Creates space from the top edge */
  bottom: 0px; /* Creates space from the bottom edge */
  width: 5px; /* The width of the rectangle */
  background-color: #05D8A5; /* Green color */
  border-radius: 2px; /* Rounded corners */
}
/* CSS class for the rectangle on available dates */
.rectangle-negative {
  position: absolute;
  left: 0;
  top: 0px; /* Creates space from the top edge */
  bottom: 0px; /* Creates space from the bottom edge */
  width: 5px; /* The width of the rectangle */
  background-color: #E53E3E; /* Red color */
  border-radius: 2px; /* Rounded corners */
}

/* CSS class for the rectangle on unavailable dates */
.rectangle-unavailable {
  position: absolute;
  left: 0;
  top: 0px; /* Adjust to create space from the top edge */
  bottom: 0px; /* Adjust to create space from the bottom edge */
  width: 5px; /* Adjust the width as needed */
  background-color: #282828; /* Gray color or your color for unavailable dates */
  border-radius: 2px; /* Rounded corners */
}
/* CSS class for the rectangle when profit is zero */
.rectangle-zero {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0px;
  width: 5px;
  background-color: #282828; /* Gray color */
  border-radius: 2px;
}


.react-aria-CalendarHeaderCell {
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
}

.react-aria-Heading {
  text-transform: uppercase;

  font-size: small;
  color: #868686;
  letter-spacing: 2px;
}

.react-aria-Button {
  margin-bottom: 10px;
  color: #868686;
}

.react-aria-Group {
  display: flex;
  gap: 30px;
  height: 40px; /* or whatever height you deem appropriate */
  align-items: center;
  justify-content: center;
}
